import { createApi } from "@reduxjs/toolkit/query/react";
import { authProvider } from "../auth/authConfiguration";
import { createBaseQuery, fetchCall } from "./apiUtils";
import {
  ITDFAccountLeaseGroup,
  ITDFThrottlingConfiguration,
  ITDFUserQuota,
  ITDFMyUserQuota,
  ITDFTenantLeaseGroup,
  ITDFReservation,
} from "./api.types";
import { getEnvironmentConfig } from "../config/environmentConfig";
import {
  IAccountExtension,
  ILeaseAccount,
  ILeaseTenant,
  ITenantExtension,
} from "../constants/types";
import {
  HydrationTarget,
  ICreatePoolRequest,
  IPool,
  IUserAnnouncement,
  IUserAnnouncementRequest,
} from "../constants/interfaces";

const { baseUrl } = getEnvironmentConfig();

export const tripsApi = createApi({
  reducerPath: "tripsApi",
  tagTypes: [
    "TDFMyQuota",
    "TDFMyAccountLeases",
    "TDFMyTenantLeases",
    "TDFMyReservations",
    "TDFUserQuota",
    "TDFUserThrottling",
    "TDFUserAccountLeases",
    "TDFUserTenantLeases",
    "TDFUserReservations",
    "OwnedPools",
    "PoolDetails",
    "AllPools",
    "SinglePool",
    "TestResource",
    "UserAnnouncement",
    "UserAnnouncements",
    "AllRequests",
    "OwnedRequests",
    "SingleRequest",
  ],
  baseQuery: createBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      const token = await authProvider.getApiToken();
      headers.set("Authorization", `Bearer ${token.accessToken}`);

      return headers;
    },
  }),
  endpoints: (b) => ({
    getMyQuota: b.query<ITDFMyUserQuota, string>({
      query: () => ({
        url: "/v1.1/devbox/quota/developer/me",
        method: "GET",
      }),
      providesTags: ["TDFMyQuota"],
    }),
    getMyAccountLeases: b.query<ITDFAccountLeaseGroup[], string>({
      query: () => ({
        url: "/v1.1/devbox/accountleases?isSynthetic=false",
        method: "GET",
      }),
      providesTags: ["TDFMyAccountLeases"],
      transformResponse: (
        response: ITDFAccountLeaseGroup[],
        meta: any,
        arg: string
      ) => {
        response.forEach((r) => {
          r.Accounts.forEach((a) => {
            a.TenantId = r.TenantId;
          });
        });

        return response;
      },
    }),
    getDeveloperQuota: b.query<ITDFUserQuota, string>({
      query: (developerUpn) => ({
        url: "/v1.1/admin/quotas/developers",
        method: "GET",
        params: { developerUpn },
      }),
      providesTags: ["TDFUserQuota"],
      transformResponse: (response: ITDFUserQuota[]) => {
        return response[0];
      },
    }),
    setDeveloperQuota: b.mutation<void, ITDFUserQuota>({
      query: (userQuota) => ({
        url: "/v1.1/admin/quotas/developers",
        method: "POST",
        body: [userQuota],
      }),
      invalidatesTags: ["TDFUserQuota"],
    }),
    getDeveloperThrottling: b.query<ITDFThrottlingConfiguration, string>({
      query: (developerUpn) => ({
        url: `/v1.1/admin/developers/${developerUpn}/throttling/configuration`,
        method: "GET",
      }),
      providesTags: ["TDFUserThrottling"],
      transformResponse: (
        response: ITDFThrottlingConfiguration,
        meta: any,
        arg: string
      ) => {
        return { ...response, UserPrincipalName: arg };
      },
    }),
    setDeveloperThrottling: b.mutation<void, ITDFThrottlingConfiguration>({
      query: (throttlingConfiguration) => ({
        url: `/v1.1/admin/developers/${throttlingConfiguration.UserPrincipalName}/throttling/configuration`,
        method: "PATCH",
        body: throttlingConfiguration,
      }),
      invalidatesTags: ["TDFUserThrottling"],
    }),
    setDeveloperThrottlingState: b.mutation<void, string>({
      query: (developerUpn) => ({
        url: `/v1.1/admin/developers/${developerUpn}/throttling/lease/state`,
        method: "DELETE",
      }),
    }),
    getDeveloperAccountLeases: b.query<ITDFAccountLeaseGroup[], string>({
      query: (developerUpn) => ({
        url: "/v1.1/devbox/accountleases?isSynthetic=false",
        method: "GET",
        headers: {
          "TDF-ImpersonatedUserUpn": developerUpn,
        },
      }),
      providesTags: ["TDFUserAccountLeases"],
      transformResponse: (
        response: ITDFAccountLeaseGroup[],
        meta: any,
        arg: string
      ) => {
        response.forEach((r) => {
          r.Accounts.forEach((a) => {
            a.TenantId = r.TenantId;
          });
        });

        return response;
      },
    }),
    getMyTenantLeases: b.query<ITDFTenantLeaseGroup[], string>({
      query: () => ({
        url: "/v1.1/devbox/tenantleases?isSynthetic=false",
        method: "GET",
      }),
      providesTags: ["TDFMyTenantLeases"],
      transformResponse: (
        response: ITDFTenantLeaseGroup[],
        meta: any,
        arg: string
      ) => {
        response.forEach((r) => {
          r.Accounts.forEach((a) => {
            a.TenantId = r.TenantId;
          });
        });

        return response;
      },
    }),
    getDeveloperTenantLeases: b.query<ITDFTenantLeaseGroup[], string>({
      query: (developerUpn) => ({
        url: "/v1.1/devbox/tenantleases?isSynthetic=false",
        method: "GET",
        headers: {
          "TDF-ImpersonatedUserUpn": developerUpn,
        },
      }),
      providesTags: ["TDFUserTenantLeases"],
      transformResponse: (
        response: ITDFTenantLeaseGroup[],
        meta: any,
        arg: string
      ) => {
        response.forEach((r) => {
          r.Accounts.forEach((a) => {
            a.TenantId = r.TenantId;
          });
        });

        return response;
      },
    }),
    getMyReservations: b.query<ITDFReservation[], string>({
      query: () => ({
        url: "/v2.0/reservations",
        method: "GET",
      }),
      providesTags: ["TDFMyReservations"],
      transformResponse: (
        response: ITDFReservation[],
        meta: any,
        arg: string
      ) => {
        return response;
      },
    }),
    getDeveloperReservations: b.query<ITDFReservation[], string>({
      query: (developerUpn) => ({
        url: "/v2.0/reservations",
        method: "GET",
        headers: {
          "TDF-ImpersonatedUserUpn": developerUpn,
        },
      }),
      providesTags: ["TDFUserReservations"],
      transformResponse: (
        response: ITDFReservation[],
        meta: any,
        arg: string
      ) => {
        return response;
      },
    }),
    getUserAnnouncementById: b.query<IUserAnnouncement, void>({
      query: (userAnnouncementId) => ({
        url: `/v1.1/userannouncements/${userAnnouncementId}`,
        method: "GET",
      }),
      providesTags: ["UserAnnouncement"],
    }),
    getUserAnnouncements: b.query<IUserAnnouncement[], void>({
      query: () => ({
        url: `/v1.1/userannouncements`,
        method: "GET",
      }),
      providesTags: ["UserAnnouncements"],
    }),
    getAssetPoolNames: b.query<string[], void>({
      query: () => ({
        url: `/v1.1/admin/pools/names`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetMyQuotaQuery,
  useGetMyAccountLeasesQuery,
  useGetMyTenantLeasesQuery,
  useGetMyReservationsQuery,
  useGetDeveloperQuotaQuery,
  useLazyGetDeveloperQuotaQuery,
  useSetDeveloperQuotaMutation,
  useGetDeveloperThrottlingQuery,
  useSetDeveloperThrottlingMutation,
  useSetDeveloperThrottlingStateMutation,
  useGetDeveloperAccountLeasesQuery,
  useGetDeveloperTenantLeasesQuery,
  useGetDeveloperReservationsQuery,
  useGetUserAnnouncementByIdQuery,
  useGetUserAnnouncementsQuery,
  useGetAssetPoolNamesQuery,
} = tripsApi;

/**
 * Lease a account for testing or debugging purposes.
 * @param type - The type of the account to lease.
 * @param count - The number of account of this type to lease.
 * @param useOldestFirst - A boolean specifying whether to use the oldest account first.
 * @param tenantId - An optional parameter in case the user wants to lease from a previously used tenant.
 * @param upn - The user principal name in case of consumerShadowMailbox account.
 * @param secret - The authorization code to use in case of consumerShadowMailbox account.
 * @param puid - The unique identifier to use in case of consumerMailbox account.
 */
export const LeaseAccount = async (
  type: string,
  count: number,
  useOldestFirst: boolean,
  tenantId?: string,
  upn?: string,
  secret?: string,
  puid?: string
): Promise<void> => {
  const uri = `/v1.1/devbox/accountleases?isSynthetic=false`;
  const body: ILeaseAccount[] = [
    {
      Accounts: [
        {
          Type: type,
          Count: count,
          UserPrincipalName: upn,
          Secret: secret,
          Puid: puid,
        },
      ],
      Environment: "TDF",
      IsSynthetic: false,
      UseOldestFirst: useOldestFirst,
    },
  ];

  if (tenantId) {
    body[0].TenantId = tenantId;
  }

  return await fetchCall("POST", uri, false, body);
};

/**
 * Lease a tenant for testing or debugging purposes.
 * @param TestDataProfileName The test data profile name.
 */
export const LeaseTenant = async (TestDataProfileName: string): Promise<void> => {
  const uri = `/v1.1/devbox/tenantleases`;
  const body: ILeaseTenant[] = [
    {
      ServiceTreeId: "00000000-0000-0000-0000-000000000001",
      TestDataProfile: TestDataProfileName,
      Environment: "TDF",
      IsSynthetic: false,
      UseOldestFirst: false,
    },
  ];

  return await fetchCall("POST", uri, false, body);
};

/**
 * Extend a leased account by account user principal name.
 * @param upn - The user principal name of the account to extend.
 */
export const ExtendAccount = async (upn: string): Promise<void> => {
  const uri = `/v1.1/devbox/accountleases`;
  const body: IAccountExtension = {
    AccountUpn: upn,
  };

  return await fetchCall("PATCH", uri, false, body);
};

/**
 * Extend a leased tenant by tenant id.
 * @param tenantId - The id of the tenant to extend.
 */
export const ExtendTenant = async (tenantId: string): Promise<void> => {
  const uri = `/v1.1/devbox/tenantleases`;
  const body: ITenantExtension = {
    TenantId: tenantId,
  };

  return await fetchCall("PATCH", uri, false, body);
};

/**
 * Release a leased account by account user principal name.
 * @param upn - The user principal name of the account to release.
 */
export const ReleaseAccount = async (upn: string): Promise<void> => {
  const uri = `/v1.1/devbox/accountleases?accountUpn=${upn}`;
  return await fetchCall("DELETE", uri, false);
};

/**
 * Release a leased tenant by tenant identifier.
 * @param tenantId - The tenant identifier of the tenant to release.
 */
export const ReleaseTenant = async (tenantId: string): Promise<void> => {
  const uri = `/v1.1/devbox/tenantleases?tenantId=${tenantId}`;
  return await fetchCall("DELETE", uri, false);
};

/**
 * Release the reserved test resources by reservation identifier.
 * @param reservationId - The reservation identifier of the reservation to release.
 */
export const ReleaseReservation = async (
  reservationId: string
): Promise<void> => {
  const uri = `/v2.0/reservations/${reservationId}`;
  return await fetchCall("DELETE", uri, false);
};

/**
 * Creates a new user announcement.
 * @param title - The title of the user announcement.
 * @param message - The message of the user announcement.
 * @param endDate - The end date of the user announcement.
 */
export const CreateUserAnnouncement = async (
  title: string,
  message: string,
  endDate?: Date | undefined
): Promise<IUserAnnouncement> => {
  const uri = `/v1.1/userannouncements`;
  const body: IUserAnnouncementRequest = {
    Title: title,
    Message: message,
    EndDate: endDate,
  };
  return await fetchCall<IUserAnnouncement>("POST", uri, true, body);
};

/**
 * Update an announcement by announcement identifier.
 * @param userAnnouncementId - The identifier of the user announcement to update.
 */
export const UpdateUserAnnouncement = async (
  userAnnouncementId: number,
  title?: string,
  message?: string,
  endDate?: Date | undefined
): Promise<void> => {
  const uri = `/v1.1/userannouncements/${userAnnouncementId}`;

  const body: Partial<IUserAnnouncementRequest> = {};

  if (title) {
    body.Title = title;
  }

  if (message) {
    body.Message = message;
  }

  if (endDate) {
    body.EndDate = endDate;
  }

  return await fetchCall("PATCH", uri, false, body);
};

/**
 * Soft delete an announcement by announcement identifier.
 * @param userAnnouncementId - The identifier of the user announcement to delete.
 */
export const DeleteUserAnnouncement = async (
  userAnnouncementId: string
): Promise<void> => {
  const uri = `/v1.1/userannouncements/${userAnnouncementId}`;
  return await fetchCall("DELETE", uri, false);
};

/** 
 * Creates a new account pool. We do not set 'owners' here.
 * @param poolName - The name of the pool.
 * @param parentProfile - The parent profile.
 * @param targetPoolSize - The target pool size.  
 * @param cost - The cost.
 * @param provisionBufferHours - The provision buffer time before expiration, in hours.
 * @param isReservable - Is reservable.
 * @param isDataProvisioned - Is data provisioned.
 * @param isReusable - Is reusable.
 * @param provisioningConfigurations - Provisioning configs.
 */
export const CreatePool = async (
  poolName: string,
  parentProfile: string,
  targetPoolSize: number,
  cost: number,
  provisionBufferHours: number,
  isReservable: boolean,
  isDataProvisioned: boolean,
  isReusable: boolean,
  provisioningConfigurations: string[],
  hydrationTargets: HydrationTarget[]
): Promise<void> => {
  const uri = `/v1.1/admin/pools`; 
  const body: ICreatePoolRequest = {
    PoolName: poolName,
    ParentProfileName: parentProfile,
    TargetPoolSize: targetPoolSize,
    Owners: [],
    Cost: cost,
    ProvisionBufferHours: provisionBufferHours,
    IsReservable: isReservable,
    IsDataProvisioned: isDataProvisioned,
    IsReusable: isReusable,
    ProvisioningConfigurations: provisioningConfigurations,
    HydrationTargets: hydrationTargets
  };
  return await fetchCall("POST", uri, false, body);
};

export const UpdatePool = async (
  poolName: string,
  parentProfile: string,
  targetPoolSize: number,
  cost: number,
  provisionBufferHours: number,
  isReservable: boolean,
  isDataProvisioned: boolean,
  isReusable: boolean,
  provisioningConfigurations: string[],
  hydrationTargets: HydrationTarget[]
): Promise<void> => {
  const uri = `/v1.1/admin/pools`; 
  const body: ICreatePoolRequest = {
    PoolName: poolName,
    ParentProfileName: parentProfile,
    TargetPoolSize: targetPoolSize,
    Owners: [],
    Cost: cost,
    ProvisionBufferHours: provisionBufferHours,
    IsReservable: isReservable,
    IsDataProvisioned: isDataProvisioned,
    IsReusable: isReusable,
    ProvisioningConfigurations: provisioningConfigurations,
    HydrationTargets: hydrationTargets
  };
  return await fetchCall("PATCH", uri, false, body);
};

/**
 * Get Asset Pool given the pool name
 * @param name - The name of the pool to get.
 */
export const GetAssetPoolByName = async (name: string): Promise<IPool> => {
  const uri = `/v1.1/pools/${name}/details`;
  // const uri = `/v1.1/admin/pool?poolName=${name}`;
  return await fetchCall<IPool>("GET", uri);
};