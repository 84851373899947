import {
  DropdownMenuItemType,
  IChoiceGroupOption,
  IContextualMenuProps,
  IDropdownOption,
} from "@fluentui/react";

export const DARK_THEME_NAME = "Dark";

export const Consumer = "Consumer";
export const Business = "Business";
export const BusinessGroupMailbox = "BusinessGroupMailbox";
export const BusinessUserMailbox = "BusinessUserMailbox";
export const BusinessTenantShard = "BusinessTenantShard";
export const ConsumerShadowMailbox = "ConsumerShadowMailbox";
export const ConsumerMailbox = "ConsumerMailbox";
export const anySharedTenant = "anySharedTenant";
export const UseSharedTenant = "UseSharedTenant";
export const ConsumerAccountHeader = "ConsumerAccountHeader";
export const BusinessAccountHeader = "BusinessAccountHeader";
export const Unknown = "Unknown";
export const Project = "Project";
export const E3 = "E3";
export const E5 = "E5";
export const OfficeE3 = "OfficeE3"; 
export const PathfinderProject = "PathfinderProject";
export const PathfinderE3 = "PathfinderE3";
export const TrailblazerProject = "TrailblazerProject";
export const TrailblazerE3 = "TrailblazerE3";
export const TenantBase = "TenantBase";
export const CreateAction = "create";
export const UpdateAction = "update";
export const TenantPool = "Tenant";
export const AccountPool = "Account";

export const ReadAction = "Read";
export const RequestAction = "Request";
export const ApproveAction = "Approve";
export const RejectAction = "Reject";
export const CancelAction = "Cancel";
export const CreateAssetPoolRequestName = "CreateAssetPoolRequest";
export const DefaultRequestExpirationHours = 48;
export const PendingState = "Pending";
export const ApprovedState = "Approved";
export const RejectedState = "Rejected";
export const CanceledState = "Canceled";
export const RequestsQuota = 10;

export const accountTypeOptions: IChoiceGroupOption[] = [
  { key: Business, text: "Business Account" },
  { key: Consumer, text: "Consumer Account" },
];

export const tenantOptions: IChoiceGroupOption[] = [
  { key: anySharedTenant, text: "Any Shared Tenant" },
  {
    key: UseSharedTenant,
    text: "Previously Used Shared Tenant",
  },
];

export const accountCategoryOptions: IDropdownOption[] = [
  {
    key: BusinessAccountHeader,
    text: "Business Account",
    itemType: DropdownMenuItemType.Header,
  },
  { key: BusinessUserMailbox, text: "BusinessUserMailbox" },
  { key: BusinessGroupMailbox, text: "BusinessGroupMailbox" },
  { key: BusinessTenantShard, text: "BusinessTenantShard" },
  {
    key: ConsumerAccountHeader,
    text: "Consumer Account",
    itemType: DropdownMenuItemType.Header,
    disabled: true,
  },
  {
    key: ConsumerShadowMailbox,
    text: "ConsumerShadowMailbox",
    disabled: true,
  },
  { key: ConsumerMailbox, text: "ConsumerMailbox", disabled: true },
];

export const tenantSkuOptions: IDropdownOption[] = [
  {
    key: Unknown,
    text: "Unknown"
  }, 
  {
    key: Project,
    text: "Project"
  },
  {
    key: E3,
    text: "E3"
  },  
  {
    key: E5,
    text: "E5"
  },  
  {
    key: OfficeE3,
    text: "OfficeE3"
  },  
  {
    key: PathfinderProject,
    text: "PathfinderProject"
  },  
  {
    key: PathfinderE3,
    text: "PathfinderE3"
  },
  {
    key: TrailblazerProject,
    text: "TrailblazerProject"
  },
  {
    key: TrailblazerE3,
    text: "TrailblazerE3"
  },
]

export const assetPoolType: IDropdownOption[] = 
[
  {
    key: TenantPool,
    text: TenantPool
  },
  {
    key: AccountPool,
    text: AccountPool
  },
]

export const uptadeOrDeleteMenu: IContextualMenuProps = {
  items: [
    {
      key: "Update",
      text: "Update",
      iconProps: { iconName: "Refresh" },
    },
    {
      key: "Remove",
      text: "Remove",
      iconProps: { iconName: "Delete" },
    },
  ],
};

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export const tripsWikiLink =
  "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/140059/TRIPS-(aka-TDF-F5-Registration-Service)";
